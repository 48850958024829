<script>
import { onBeforeMount } from 'vue'
import ui from '/~/core/ui'
import { useAccess } from '/~/composables/access/use-access'
import router from '/~/router'
import CheckoutDesk from './checkout.desktop.vue'
import CheckoutMobile from './checkout.mobile.vue'

export default {
  name: 'checkout-v2',
  components: {
    CheckoutDesk,
    CheckoutMobile,
  },
  setup() {
    const { withAccessCheck } = useAccess()

    onBeforeMount(() => {
      withAccessCheck({
        reject: () => {
          router.push({ name: 'home' })
        },
      })
    })

    return {
      ui,
    }
  },
}
</script>

<template>
  <checkout-desk v-if="!ui.mobile" v-bind="$attrs" v-on="$listeners" />
  <checkout-mobile v-else v-bind="$attrs" v-on="$listeners" />
</template>
